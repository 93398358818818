.Join {
  
}


.Join .content > .mercy {
  grid-column: 2;
}


.Join .content > div {
  margin: 16px 96px;
  font-family: Georgia, serif;
  color: #ffffff;
  width: calc(100% - 192px);
  grid-column: 1;
}

.Join .content h2 {
  font-size: min(24px, 2vw);
  width: 100%;
  margin: 16px 0px;
  /* text-align: center; */
}

.Join .content p {
  font-size: min(18px, 1.5vw);
  line-height: 1.6;
  margin: 16px 0px;
}

.Join .content a {
  font-size: min(18px, 1.5vw);
  color: #99e1ff;
}

.Join .content .sep {
  height: 16px;
}