.VideoElem {
  margin: 0 48px 32px;
  width: fit-content;
  height: fit-content;
}

.VideoElem > iframe {
  width: 600px;
  height: 338px;
  border: 1px solid #4a4a4a;
}