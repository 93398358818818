.Bibliography {
  
}

.Bibliography .content {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 600px 600px;
  margin-top: 96px;
}

.Bibliography .content h1 {
  grid-column: 1 / 3;
}

.Bibliography .content > div {
  margin: 16px 96px;
  font-family: Georgia, serif;
  color: #63cdfa;
  width: calc(100% - 192px);
  grid-column: 1;
}

.Bibliography .content h2 {
  font-size: min(24px, 2vw);
  width: 100%;
  margin: 16px 0px;
  /* text-align: center; */
}

.Bibliography .content p {
  line-height: 1.6;
  margin: 16px 0px;
}

.Bibliography .content a {
  color: #99e1ff;
}

/* .Join .mission {
  grid-row: 2;
}

.Join .email {
  grid-row: 3;
}

.Join .join-info {
  grid-row: 4;
} */