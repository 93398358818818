html {
  background-color: #000000;
  display: grid;
  justify-content: center;
}

html, body{ 
  width:100%;
  overflow-x: hidden;
}

body.noscroll {
  /* overflow: hidden; */
}

.HOC {
  width: 100vw;

  /* height: 100vh; */
  /* margin-top: 64px; */

  /* Center and scale the image nicely */
  /* min-height: calc(120vh); */
}

.hoc-children {
  min-height: calc(100vh - calc(72px + min(8vw, 96px)));
}

.HOC .content > .mercy {
  grid-column: 2;
  width: 100%;
  grid-row: 1 / 6;
  margin: 0;
  margin-top: 32px;
  margin-left: -60%;
  width: 100%;
  align-self: flex-start;
}

.HOC .mercy img {
  width: 120%;
}

.HOC * {
  z-index: 3;
  position: relative;
}

#bkgd {
  width: 100vw;
  min-height: 100vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  padding-bottom: 16px;
  margin-top: -16px;
}



.HOC h1 {
  font-family: Georgia, serif;
  color: #63cdfa;
  font-size: min(72px, 6vw);
  width: 100%;
  margin: 24px 0px;
  text-align: center;
}

.HOC .content {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-rows: auto auto;
  justify-items: center;
  align-items: center;
}

/* a > img {
  padding: 2px;
}

a > img:hover {
  cursor: pointer;
  padding: 0;
  border: 2px solid #b9534588;
  border-radius: 10px;
} */
/* 
@media screen and (max-width: 800px) {
  .videos-contain .vid-block {
    width: fit-content;
    flex-direction: column;
  }

  .videos-contain .vid-block .VideoElem {
    margin: 0 0 48px;
  }

  .HOC .contact-contain {
    grid-template-columns: 100%;
  }

  .HOC .contact-contain > img {
    grid-column: 1;
    grid-row: 3;
  }

  .HOC .subcontacts {
    margin: 24px 0;
  }
} */