

footer {
    height: 72px;
    display: grid;
    align-items: center; 
    position: relative;
  }
  
  footer > span {
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
    color: #ffffff;
    margin: 0 48px;
  }