.Photos {
  
}

.Photos h1 {
  grid-column: 1 / 3;
  
}

.Photos .subtitle {
  font-family: Georgia, serif;
  color: #63cdfa;
  font-size: min(24px, 2vw);
  width: 100%;
  margin: 16px 0px;
  /* text-align: center; */
}

.Photos .content {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 600px 600px;
  margin-top: 96px;
}

.Photos .content img {
  max-width: 80%;
  max-height: calc(100% - 96px);
  margin: 0 0 96px 0;
}