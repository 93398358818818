.NavBar {
    height: min(8vw, 96px);
    /* border-bottom: 1px #cecece solid; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 min(4vw, 64px);
    z-index: 4;
  }
  
  
  .navButton, .navButton-hidden {
    visibility: visible;
    display: grid;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
    /* margin-top: 1px; */
    z-index: 2;
    text-wrap: nowrap;
  }
  
  .navButton-hidden {
    border-bottom: 3px #cecece solid;
    padding-bottom: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    position: absolute;
    z-index: 4;
  }
  
  .navButton > span, .navButton-hidden > span {
    font-family: Georgia, serif;
    color: #63cdfa;
    /* text-transform: uppercase; */
    /* font-size: ; */
    font-size: min(2.4vw, 28px);
    font-weight: bold;
    margin: min(2.4vw, 28px) 0;
  }
  
  .navButton-hidden > span {
    color: #cecece;
    text-decoration: none;
  }
  
  .navButton-hidden:hover, .navButton-hidden.shown {
    opacity: 1;
    cursor: pointer;
  }

  /* Dropdown */

  .dropbtn {
    /* padding: 16px; */
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: 24px;
  }
  

  .dropdown-content-wrapper {
    position: absolute;
    padding-bottom: 8px;
    opacity: 0;
    visibility: hidden;
  }

  .dropdown-content {
    background-color: #f9f9f9;
    width: 22vw;
    max-width: 350px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid #dedede;
  }
  

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid #dedede;
    cursor: pointer;
  }
  

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  

  .dropdown:hover .dropdown-content-wrapper {
    /* display: block; */
    opacity: 1;
    visibility: visible;
  }

  .navButton-hidden, .dropdown-content {
    transition-property: opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }