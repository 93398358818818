
  
.Home {

}

.Home .title {
  /* width: 840px; */
}

.Home .doc img {
  width: 100%;
  border-radius: 4px;
}

.Home .doc {
  grid-row: 2;
  width: min(590px, 58vw);
  margin-top: 16px;
}

.Home .doc p {
  font-family: Georgia, serif;
  font-size: min(19px, 2vw);
  font-weight: bold;
  font-style: italic;
  /* text-align: right; */
  line-height: 1.5;
  color: #cecece;
  width: fit-content;
  padding: 4px 16px;
  margin: 0;
}

.Home .doc .subtext {
  padding: 8px 4px;
  margin: 8px 0 0px;
  display: grid;
  justify-items: flex-end;
}




.Home .mission-contain {
  display: grid;
  margin-top: 32px;
}

.Home .mission-contain > div {
  margin: 16px 96px;
  font-family: Georgia, serif;
  color: #ffffff;
  width: calc(100% - 192px);
  grid-column: 1;
}

.Home .mission-contain h2 {
  font-size: min(24px, 2vw);
  width: 100%;
  margin: 16px 0px;
  /* text-align: center; */
}

.Home .mission-contain p {
  font-size: min(18px, 1.5vw);
  line-height: 1.6;
  margin: 16px 0px;
}

.Home .mission-contain a {
  font-size: min(18px, 1.5vw);
  color: #99e1ff;
}